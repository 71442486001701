.ReactModal__Overlay {
	-webkit-perspective: 600;
	perspective: 600;
	opacity: 0;
	overflow-x: hidden;
	overflow-y: auto;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Content {
	-webkit-transform: scale(0.5) rotateX(-30deg);
	transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
	-webkit-transform: scale(1) rotateX(0deg);
	transform: scale(1) rotateX(0deg);
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}

.ReactModal__Content--before-close {
	-webkit-transform: scale(0.5) rotateX(30deg);
	transform: scale(0.5) rotateX(30deg);
}

.ReactModal__Content.modal-dialog {
	position: fixed;
	top: 50%;
	right: auto;
	bottom: auto;
	left: 50%;
	transform: translate(-50%,-50%);
	outline: 0;
	
}

